body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark-background);
  color: var(--text);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  padding: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.theme-dark {
  --dark-text: #e5e5e5;
  --text: #ffffff;
  --dark-background: #191d21;
  --background: #2f3337;
  --background-success: #00b74a;
  --background-info: #39c0ed;
  --background-warning: #ffa900;
  --background-danger: #f93154;
  --background-danger-hover-background-color: #797b7e;
  --background-danger-hover-color: #cccdce;
  --background-danger-background-color: #585b5e;
  --background-danger-color: #e5e5e5;
  --accent: hsl(210, 2%, 47%);
  --btn-primary-background-color: #1266f1;
  --btn-success-background-color: #00b74a;
  --btn-secondary-background-color: #6d7073;
  --btn-info-background-color: #39c0ed;
  --btn-warning-background-color: #ffa900;
  --btn-danger-background-color: #f80c35;
  --modal-title-background-color: #202326;
  --spacer: 0.25rem;
  --columns-xxl: 2;
  --alert-success-color: #7fffb2;
  --alert-success-background-color: #134730;
  --alert-info-color: #72c3dd;
  --alert-info-background-color: #073746;
  --alert-warning-color: #ffd685;
  --alert-warning-background-color: #523600;
  --alert-danger-color: #e26c81;
  --alert-danger-background-color: #4a020f;
  --placeholder-color: #8a8c8e;
  --accordion-button-active-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e5e5e5'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --accordion-button-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e5e5e5'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.theme-light {
  --dark-text: #191d21;
  --text: #363636;
  --dark-background: #cecece;
  --background: #e5e5e5;
  --background-success: #00b74a;
  --background-warning: #ffa900;
  --background-info: #39c0ed;
  --background-danger: #f93154;
  --background-danger-hover-background-color: #585b5e;
  --background-danger-hover-color: #e5e5e5;
  --background-danger-background-color: #b9b9b9;
  --background-danger-color: #292929;
  --accent: #a4a4a4;
  --btn-primary-background-color: #2db7e2;
  --btn-secondary-background-color: #9e9e9e;
  --btn-info-background-color: #033c73;
  --btn-success-background-color: #18bc9c;
  --btn-warning-background-color: #f39c12;
  --btn-danger-background-color: #e74c3c;
  --modal-title-background-color: #aaaaaa;
  --spacer: 0.25rem;
  --alert-success-color: #589359;
  --alert-success-background-color: #c1e2b3;
  --alert-info-color: #2d6987;
  --alert-info-background-color: #afd9ee;
  /* --alert-warning-color: #a47e3c; */
  /* --alert-warning-background-color: #f7ecb5; */
  --alert-warning-color: #825e00;
  --alert-warning-background-color: #e8c466;
  --alert-danger-color: #953b39;
  --alert-danger-background-color: #e4b9b9;
  --placeholder-color: #7a7a7a;
  --accordion-button-active-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23292929'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --accordion-button-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23292929'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar {
  background: #343a40;
}
.navbar-brand > img {
  height: 2rem;
  padding-right: 1rem;
}
.nav-link {
  color: #ffffff;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.dropdown-menu {
  background-color: var(--background);
  border-color: var(--dark-background);
  border-width: 0.1rem;
}
.dropdown-item {
  background-color: var(--background);
  color: var(--dark-text);
}
.dropdown-item:focus {
  background-color: var(--dark-background);
}
.dropdown-item:hover {
  filter: brightness(40%);
}
.dropdown-item:active,
.dropdown-item.active {
  background-color: var(--dark-background);
  color: var(--text);
}
.dropdown-item.active:hover {
  filter: brightness(200%);
}
.dropdown-item.active:focus {
  background-color: var(--background);
}

.r-3 {
  margin-right: var(--spacer) !important;
}
.rounded-bottom {
  border-radius: 0 0 0.5rem 0.5rem !important;
}

.form-floating {
  color: black;
}

.services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 1.25rem;
}

.service {
  border: none;
  background: var(--background);
  color: var(--dark-text);
  height: 100%;
  border-radius: 0.75rem;
}

.service-disabled {
  background: repeating-linear-gradient(
    60deg,
    var(--background),
    var(--background) 0.75rem,
    var(--btn-secondary-background-color) 0.75rem,
    var(--btn-secondary-background-color) 1rem
  ) !important;
  border: 0.2rem solid var(--btn-secondary-background-color) !important;
}

.service-inner {
  border: none;
  margin: 0.5rem;
  background: var(--background);
  color: var(--dark-text);
  display: flex;
  margin-top: auto;
}
.service-item {
  display: flex;
  padding: 0px;
  justify-content: space-between;
  border: none;
  overflow: hidden;
}
.list-group-flush {
  padding: 0.5rem;
}
.btn-update-action {
  height: 1.25rem;
}
.background-none {
  background: none !important;
  border-style: none;
}
.pad-no {
  padding: 0 0.5rem 0 0.5rem !important;
}

.alert-primary {
  color: var(--alert-primary-color) !important;
  background-color: var(--alert-primary-background-color) !important;
  border-color: var(--alert-primary-background-color) !important;
}
.alert-success {
  color: var(--alert-success-color) !important;
  background-color: var(--alert-success-background-color) !important;
  border-color: var(--alert-success-background-color) !important;
}
.alert-info {
  color: var(--alert-info-color) !important;
  background-color: var(--alert-info-background-color) !important;
  border-color: var(--alert-info-background-color) !important;
}
.alert-warning {
  color: var(--alert-warning-color) !important;
  background-color: var(--alert-warning-background-color) !important;
  border-color: var(--alert-warning-background-color) !important;
}
.alert-danger {
  color: var(--alert-danger-color) !important;
  background-color: var(--alert-danger-background-color) !important;
  border-color: var(--alert-danger-background-color) !important;
}

.icon-success {
  color: var(--background-success) !important;
}
.icon-warning {
  color: var(--background-warning) !important;
}
.icon-danger {
  color: var(--background-danger) !important;
}

.update-options {
  overflow: hidden;
  text-align: center;
}

.btn-icon-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
}
.btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.btn-primary {
  background-color: var(--btn-primary-background-color);
  border-color: var(--btn-primary-background-color);
  color: var(--dark-text) !important;
}
.btn-primary:hover {
  filter: brightness(120%);
}
.btn-primary:focus {
  filter: brightness(80%);
  box-shadow: none;
}
.btn-primary:disabled {
  filter: brightness(70%);
}

.btn-secondary {
  background-color: var(--btn-secondary-background-color);
  border-color: var(--btn-secondary-background-color);
  color: var(--dark-text) !important;
}
.btn-secondary:hover {
  filter: brightness(120%);
}
.btn-secondary:focus {
  filter: brightness(80%);
  box-shadow: none;
}
.btn-secondary:disabled {
  filter: brightness(70%);
}
.btn-secondary-outlined {
  background-color: transparent;
  border-color: var(--btn-secondary-background-color);
  border-width: 0.1rem;
  color: var(--dark-text) !important;
}
.btn-secondary-outlined:hover {
  background-color: var(--btn-secondary-background-color);
  border-color: var(--btn-secondary-background-color);
  filter: brightness(120%);
}
.btn-secondary-outlined:focus {
  background-color: var(--btn-secondary-background-color);
  border-color: var(--btn-secondary-background-color);
  filter: brightness(80%);
  box-shadow: none;
}
.btn-secondary-outlined:disabled {
  background-color: var(--btn-secondary-background-color);
  border-color: var(--btn-secondary-background-color);
  filter: brightness(70%);
}

.btn-success {
  background-color: var(--btn-success-background-color);
  border-color: var(--btn-success-background-color);
  color: var(--dark-text) !important;
}
.btn-success:hover {
  filter: brightness(120%);
}
.btn-success:focus {
  filter: brightness(80%);
  box-shadow: none;
}
.btn-success:disabled {
  filter: brightness(70%);
}

.btn-warning {
  background-color: var(--btn-warning-background-color);
  border-color: var(--btn-warning-background-color);
  color: var(--dark-text) !important;
}
.btn-warning:hover {
  filter: brightness(120%);
  color: var(--dark-text) !important;
}
.btn-warning:focus {
  filter: brightness(80%);
  color: wheat;
  box-shadow: none;
}
.btn-warning:disabled {
  filter: brightness(70%);
}

.btn-danger {
  background-color: var(--btn-danger-background-color);
  border-color: var(--btn-danger-background-color);
  color: var(--dark-text) !important;
}
.btn-danger:hover {
  filter: brightness(120%);
  box-shadow: none;
}
.btn-danger:focus {
  filter: brightness(80%);
  box-shadow: none;
}
.btn-danger:disabled {
  filter: brightness(70%);
}

.justify-right {
  justify-content: right !important;
}
.justify-left {
  justify-content: left !important;
}

.same-color {
  color: var(--dark-text) !important;
}
.same-color-as-background {
  color: var(--background);
}
.btn-unchecked {
  color: var(--dark-text) !important;
  background: none !important;
  border-style: none;
}

.card-footer {
  border-top: none;
  font-size: 0.675em;
  padding: 0 !important;
  background-color: transparent;
}

.service-title {
  padding: 0.5rem 0.75rem 0px 0.75rem;
}
.service-image {
  padding: 0.5rem 0;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  margin: auto;
}

.bg-secondary {
  background-color: var(--background) !important;
  color: var(--dark-text);
}

.list-group-item-secondary {
  background-color: transparent;
  color: var(--dark-text);
}
.list-group-item-warning {
  color: var(--alert-warning-color) !important;
  background-color: var(--alert-warning-background-color) !important;
}

.row {
  min-width: 100% !important;
  --bs-gutter-x: 0;
}

.modal-content {
  border: var(--modal-title-background-color) 0.1rem solid;
  background-color: var(--background);
  color: var(--dark-text);
}
.modal-header {
  border-bottom: none;
  background-color: var(--modal-title-background-color) !important;
}
.modal-body {
  white-space: pre-wrap;
  padding: 0.75rem;
}
.modal-footer {
  border-top: none;
}

.text-muted {
  justify-content: center;
  display: flex;
}

.no-margin {
  margin: 0px;
}
.empty {
  margin: 0px;
  padding: 0px;
}

.modal-item-title {
  margin: 0px;
  padding: 0.5rem;
  font-size: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.capitalize-title {
  text-transform: lowercase;
}
.capitalize-title::first-letter {
  text-transform: capitalize;
}

.config {
  background-color: var(--background);
  padding: 1rem;
  /* whiteSpace: "break-spaces" */
}

.spinner {
  color: var(--placeholder-color);
}

.hiddenElement {
  visibility: hidden;
  pointer-events: none;
}

.accordion {
  border: var(--dark-background) 0.1em solid;
  border-radius: 0.375rem;
}
.accordion-item {
  background-color: var(--background);
  color: var(--dark-text);
  border: 0.2em solid var(--white);
}
.accordion-body {
  padding: 0.5rem 1rem 1rem;
}
.accordion-button {
  background-color: var(--dark-background);
  color: var(--text);
  border: 0.2em solid var(--white);
}
.accordion-button:not(.collapsed) {
  background-color: var(--dark-background);
  color: var(--text);
  box-shadow: none;
}
.accordion-button.collapsed::after {
  /* Down chevron */
  background-image: var(--accordion-button-background-image);
}
.accordion-button:not(.collapsed)::after {
  /* Up chevron */
  background-image: var(--accordion-button-active-background-image);
}
.accordion-button:focus {
  box-shadow: none;
}

.col-form {
  align-self: start;
}
.form-control-color {
  filter: brightness(100%) !important;
  padding: 1px;
}
.form-label {
  margin-bottom: 0.25rem;
}
.form-control {
  border-color: var(--btn-secondary-background-color);
  background-color: transparent;
  color: var(--dark-text);
}
.form-control:focus {
  background-color: var(--dark-background);
  color: var(--text);
  box-shadow: var(--dark-text);
}
.form-control::placeholder {
  color: var(--accent);
}
.form-select {
  border-color: var(--btn-secondary-background-color);
  background-color: transparent;
  color: var(--dark-text);
}
.form-select:focus {
  filter: brightness(50%);
  box-shadow: var(--dark-text);
}
.form-select-option {
  background-color: var(--dark-background);
  color: var(--text);
}
.form-error {
  border-color: var(--alert-danger-color);
  background-color: var(--alert-danger-background-color);
  border-left: 1rem solid var(--alert-danger-color);
}
.form-error:focus {
  border-color: var(--alert-danger-color);
}
.form-check {
  height: 100%;
}
.form-check-input {
  background-color: var(--dark-background);
  color: var(--text);
  border-color: var(--btn-secondary-background-color);
}
.form-check-large .form-check-input {
  height: 2rem;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}
.form-check-input:checked {
  background-color: var(--btn-secondary-background-color);
  border-color: var(--btn-secondary-background-color);
}

.nav-pills .nav-link {
  border: var(--btn-secondary-background-color) 0.1em solid;
  color: var(--text);
}
.nav-pills .nav-link.active {
  background-color: var(--btn-secondary-background-color);
  color: var(--text);
}
.nav-pills .nav-link:hover {
  color: var(--text);
}
.nav-pills .nav-link:focus {
  color: var(--text);
}
.nav-item {
  margin-bottom: -0.25rem;
}

.bold-underline {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-color: var(--accent);
}

.error-msg {
  color: var(--alert-danger-color);
}

.bg-transparent {
  background-color: transparent;
}

/* Hide spinners - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners - Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
